// brand shop
export const REQUEST_CATEGORIES = 'zola/registryCollection/REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'zola/registryCollection/RECEIVE_CATEGORIES';
export const REQUEST_DIRECTORY = 'zola/registryCollection/REQUEST_DIRECTORY';
export const RECEIVE_DIRECTORY = 'zola/registryCollection/RECEIVE_DIRECTORY';
// shop
export const REQUEST_PRODUCTS = 'zola/shop/REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'zola/shop/RECEIVE_PRODUCTS';
export const REQUEST_CATEGORY_TREE = 'zola/shop/REQUEST_CATEGORY_TREE';
export const RECEIVE_CATEGORY_TREE = 'zola/shop/RECEIVE_CATEGORY_TREE';
