import { schema } from 'normalizr';
import _omit from 'lodash/omit';

const elementEntity = new schema.Entity(
  'elements',
  {},
  {
    idAttribute: 'uuid',
  }
);

const pageEntity = new schema.Entity(
  'pages',
  {
    elements: [elementEntity],
  },
  {
    idAttribute: 'uuid',
  }
);

export const customizationEntity = new schema.Entity(
  'customizations',
  {
    pages: [pageEntity],
  },
  {
    idAttribute: 'uuid',
    processStrategy: customization => _omit(customization, 'fields'),
  }
);

export const customizationObject = new schema.Object({
  pages: [pageEntity],
});

const projectSchema = new schema.Object({
  customizations: [customizationEntity],
});

export default projectSchema;
