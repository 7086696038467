import type { WThemeGroupView, WThemeView } from '@zola/svc-web-api-ts-client';

import {
  RECEIVE_WEBSITE_PDP_THEME,
  RECEIVE_WEBSITE_PDP_THEME_GROUP,
  WebsitePdpActions,
} from './websitePdpActions';

export type WebsitePdpPageState = {
  themeGroup?: WThemeGroupView;
  currentTheme?: WThemeView;
};

const initialState: WebsitePdpPageState = {
  currentTheme: {},
  themeGroup: {},
};

const websitePdpReducer = (
  state = initialState,
  action: WebsitePdpActions
): WebsitePdpPageState => {
  switch (action.type) {
    case RECEIVE_WEBSITE_PDP_THEME:
      return { ...state, currentTheme: action.payload.theme };
    case RECEIVE_WEBSITE_PDP_THEME_GROUP:
      return { ...state, themeGroup: action.payload.themeGroup };
    default:
      return state;
  }
};

export default websitePdpReducer;
