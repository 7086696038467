/**
 * Created by zoechodosh on 5/12/17.
 */
import * as ActionTypes from '../actions/types/ModuleGroupActionTypes';

const initialState = {
  busy: true,
};

const moduleGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_MODULE_GROUP: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_MODULE_GROUP: {
      const moduleGroup = {};
      moduleGroup[action.payload.tag] = action.payload;
      return Object.assign({}, state, moduleGroup);
    }
    default:
      return state;
  }
};

export default moduleGroupReducer;
