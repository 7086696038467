import * as ActionTypes from '../actions/types/ShopActionTypes';

const initialState = {
  busy: false,
  categories: [{ label: 'All Categories', key: null, id: null }],
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_CATEGORIES: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_CATEGORIES: {
      return {
        ...state,
        busy: false,
        categories: [{ label: 'All Categories', key: null, id: null }, ...action.payload],
      };
    }
    case ActionTypes.REQUEST_DIRECTORY: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_DIRECTORY: {
      return { ...state, busy: false, directory: action.payload };
    }
    case ActionTypes.REQUEST_CATEGORY_TREE: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_CATEGORY_TREE: {
      return { ...state, busy: false, categoryTree: action.payload };
    }
    case ActionTypes.REQUEST_PRODUCTS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_PRODUCTS: {
      return { ...state, busy: false, products: action.payload };
    }
    default:
      return state;
  }
};

export default shopReducer;
