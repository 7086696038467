export const STEPS = {
  detailFront: 'detailFront',
  detailInside: 'detailInside',
  detailWrite: 'detailWrite',
  detailBack: 'detailBack',
  detailAssign: 'detailAssign',
  envelope: 'envelope',
  recipients: 'recipients',
  addressing: 'addressing',
  addressingPreview: 'addressingPreview',
  rsvp: 'rsvp',
  rsvpEnvelope: 'rsvpEnvelope',
  reviewPaper: 'reviewPaper',
  front: 'front',
  details: 'details',
  links: 'links',
  freeSamples: 'freeSamples',
  share: 'share',
  addMorePaper: 'addMorePaper',
};

export const ADDRESSING_STEPS = [STEPS.recipients, STEPS.addressing, STEPS.addressingPreview];

export const STEPS_ALLOWING_MULTIPLES = [
  STEPS.detailFront,
  STEPS.detailBack,
  STEPS.detailInside,
  STEPS.detailWrite,
  STEPS.detailAssign,
  STEPS.front,
  STEPS.details,
  STEPS.freeSamples,
  STEPS.share,
];

export const STEPS_WITH_FOIL = [STEPS.rsvp, STEPS.detailFront];

export const STEPS_WITHOUT_MOBILE_EDITOR = [
  STEPS.recipients,
  STEPS.addressing,
  STEPS.reviewPaper,
  STEPS.detailInside,
  STEPS.detailWrite,
];
