import type {
  WThemeFacetView,
  ThemeFacetView,
  WThemeGroupSearchResultView,
  WThemeGroupSearchResultsView,
  WThemeSearchRequest,
  WThemeSortView,
  ThemeSortView,
} from '@zola/svc-web-api-ts-client';

import { PLPFilterOptions } from '../helpers';
import {
  WebsitePlpActions,
  RECEIVE_THEME_SEARCH,
  UPDATE_THEME_FILTERS,
  UPDATE_THEME_SORT,
  REQUEST_THEMES,
  CLEAR_THEMES,
} from './websitePlpActions';

const initialState: WebsitePlpPageState = {
  busy: false,
  applied_filters: { colors: [], special_features: [], motifs: [] },
  facets: [],
  displayable_total: undefined,
  sorts: [],
  applied_sort: ('FEATURED' as unknown) as WThemeSearchRequest.SortKeyEnum,
  theme_groups: [],
  hasMore: false,
};

export type WebsitePlpPageState = {
  busy: boolean;
  applied_filters: PLPFilterOptions;
  facets: (ThemeFacetView | WThemeFacetView)[];
  sorts: WThemeSortView[] | ThemeSortView[];
  displayable_total?: number;
  theme_groups: (WThemeGroupSearchResultView | WThemeGroupSearchResultsView)[];
  applied_sort: WThemeSearchRequest.SortKeyEnum;
  hasMore: boolean;
};

export const websitePlpReducer = (
  state = initialState,
  action: WebsitePlpActions
): WebsitePlpPageState => {
  switch (action.type) {
    case RECEIVE_THEME_SEARCH: {
      const themeGroups = state.theme_groups.concat(action.payload.theme_groups);
      return { ...state, ...action.payload, theme_groups: themeGroups };
    }
    case UPDATE_THEME_FILTERS:
      return { ...state, applied_filters: action.payload.filters };
    case UPDATE_THEME_SORT:
      return { ...state, applied_sort: action.payload.applied_sort };
    case REQUEST_THEMES:
      return { ...state, busy: true };
    case CLEAR_THEMES:
      return { ...state, theme_groups: [] };
    default:
      return state;
  }
};

export default websitePlpReducer;
