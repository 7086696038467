import type {
  WThemeGroupView,
  WThemeView,
  WWeddingAccountView,
  WWeddingView,
} from '@zola/svc-web-api-ts-client';

export const UPDATE_WEDDING = 'zola/wedding/update';
export const REQUEST_WEDDING = 'zola/wedding/request';
export const RECEIVE_WEDDING = 'zola/wedding/receive';
export const RECEIVE_WEDDING_ACCOUNT = 'zola/wedding/receive_account';
export const THEME_REQUESTED = 'zola/wedding/THEME_REQUESTED';
export const THEME_RECEIVED = 'zola/wedding/THEME_RECEIVED';
export const THEMEGROUP_REQUESTED = 'zola/wedding/THEMEGROUP_REQUESTED';
export const THEMEGROUP_RECEIVED = 'zola/wedding/THEMEGROUP_RECEIVED';
export const ENABLE_GUEST_LIST_ONBOARDING = 'zola/wedding/ENABLE_GUEST_LIST_ONBOARDING';
export const ENABLE_GUEST_LIST_TOUR = 'zola/wedding/ENABLE_GUEST_LIST_TOUR';
export const DISABLE_WEBSITE_ONBOARDING = 'zola/wedding/DISABLE_WEBSITE_ONBOARDING';

type RequestWeddingActionType = {
  type: typeof REQUEST_WEDDING;
};

type ReceiveWeddingAccountActionType = {
  type: typeof RECEIVE_WEDDING_ACCOUNT;
  payload: { account: WWeddingAccountView };
};

type ReceiveWeddingActionType = {
  type: typeof RECEIVE_WEDDING;
  payload: { wedding: WWeddingView };
};

type RequestThemeActionType = {
  type: typeof THEME_REQUESTED;
};

export type ReceiveThemeActionType = {
  type: typeof THEME_RECEIVED;
  payload: { theme: WThemeView };
};

type RequestThemeGroupActionType = {
  type: typeof THEMEGROUP_REQUESTED;
};

type ReceiveThemeGroupActionType = {
  type: typeof THEMEGROUP_RECEIVED;
  payload: { theme: WThemeGroupView };
};

type DisableWebsiteOnboardingActionType = {
  type: typeof DISABLE_WEBSITE_ONBOARDING;
};

type EnableGuestListOnboardingActionType = {
  type: typeof ENABLE_GUEST_LIST_ONBOARDING;
};

type EnableGuestListTourActionType = {
  type: typeof ENABLE_GUEST_LIST_TOUR;
};

export type WeddingActionTypes =
  | RequestWeddingActionType
  | ReceiveWeddingAccountActionType
  | ReceiveWeddingActionType
  | RequestThemeActionType
  | ReceiveThemeActionType
  | RequestThemeGroupActionType
  | ReceiveThemeGroupActionType
  | DisableWebsiteOnboardingActionType
  | EnableGuestListOnboardingActionType
  | EnableGuestListTourActionType;
